// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { graphql } from 'gatsby'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import Button from 'antd/lib/button'
import 'antd/lib/button/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import timeAgo from '../methods/time-ago'
import formattedDate from '../methods/formatted-date'
import getRandomArbitraryInt from '../methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Community Events',
  slug: '/community-events',
  abstract:
    'A list of our upcoming and past events held in the community including presentations of our project, launch events as well as the Unfold Your Skills workshops.',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Community Events', slug: '/community-events' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query CommunityEventsPageQuery {
    allEventsJson(filter: { tags: { in: ["Community Event"] } }) {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
          shortAbstract
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ data, mediaState, ...restProps }) => {
  const {
    allEventsJson: { edges },
  } = data
  const nodes = map(edges, 'node')
  const orderedNodes = orderBy(nodes, ['publishedTimestamp'], ['desc'])

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...restProps}>
      <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
      <h1>Community Events</h1>
      <p
      // style={{ maxWidth: 'unset', columnCount: 2, textAlign: 'justify' }}
      >
        A list of our upcoming and past events held in the community including
        presentations of our project, launch events as well as the Unfold Your
        Skills workshops.
      </p>
      <br />
      {map(orderedNodes, (node) => (
        <Link to={node.routeSlug} className="event">
          <Row
            gutter={[
              { xs: 16, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={0} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <p style={{ textAlign: 'right' }}>
                {formattedDate(node.publishedTimestamp)}
                <small
                  style={{
                    fontSize: '80%',
                    display: 'block',
                    marginTop: '0.15rem',
                  }}
                >
                  {timeAgo(node.publishedTimestamp)}
                </small>
              </p>
            </Col>
            <Col xs={24} sm={10} md={8} lg={8} xl={8} xxl={8}>
              <GatsbyImage
                image={getImage(node.cover)}
                style={{ marginTop: '-4px' }}
              />
            </Col>
            <Col xs={24} sm={10} md={12} lg={12} xl={12} xxl={12}>
              <div className="pad">
                <h3>{node.title}</h3>
                <p>
                  <span className="hidden-on-tablet hidden-on-desktop hidden-on-large-desktop hidden-on-extra-large-desktop">
                    {formattedDate(node.publishedTimestamp)}.&nbsp;
                  </span>
                  {node.shortAbstract}
                </p>
              </div>
            </Col>
          </Row>
        </Link>
      ))}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // dispatch => ({
  //   updateWebsiteTheme(payload) {
  //     dispatch(updateWebsiteTheme(payload))
  //   },
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
